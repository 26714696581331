import { ecommerce } from '..';
import { getFraudTemplates } from './getFraudTemplates';
import { createFraudTemplate } from './createFraudTemplate';
import { getFraudTemplateById } from './getFraudTemplateById';
import { updateFraudTemplate } from './updateFraudTemplate';
import { deleteFraudTemplate } from './deleteFraudTemplate';

export const api = ecommerce;

export { getFraudTemplates, createFraudTemplate, getFraudTemplateById, updateFraudTemplate, deleteFraudTemplate };
