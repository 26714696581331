import { api } from '.';
import { FRAUD_TEMPLATE_RESOURCE } from '@/services/keys';

export const getResources = () => ({
  [FRAUD_TEMPLATE_RESOURCE]: undefined,
});

/**
 *
 * @param {FraudTemplate} fraudTemplate
 * @returns {Promise<string>}
 */
export async function createFraudTemplate(fraudTemplate) {
  const partialUrl = api.createUrl(getResources());
  const {
    data: { offer: id },
  } = await api.create(partialUrl, fraudTemplate.payload());
  return id;
}
