// TODO: we need to join values and keys

export const BREAKDOWN_VALUES = Object.freeze({
  day: 'day',
  publisher: 'publisher',
  offer: 'offer',
  month: 'month',
  advertiser: 'advertiser',
  hour: 'hour',
  pubId: 'subPublisher',
  creative_type: 'creative_type',
  creative: 'creative',
  subPublisherCategory: 'subPublisherCategory',
});

export const BREAKDOWN_SORT_KEY = Object.freeze({
  day: 'day',
  publisher: 'publisherName',
  offer: 'offerName',
  month: 'month',
  hour: 'hour',
  subPublisher: 'subPublisher',
  advertiser: 'advertiserName',
  creative_type: 'creative_type',
  creative: 'creativeName',
  subPublisherCategory: 'TODO',
});
export const BREAKDOWN_QUICK_FILTER_KEY = Object.freeze({
  publisher: 'publisherName',
  offer: 'offerName',
});
