/// <reference path="../../../../entities/ecommerce/Offer.js" />

import { api } from '.';
import { FRAUD_TEMPLATE_RESOURCE } from '@/services/keys';

export const getResources = fraudTemplateId => ({
  [FRAUD_TEMPLATE_RESOURCE]: fraudTemplateId,
});

/**
 *
 * @param {FraudTemplate} fraudTemplate
 * @returns {Promise.<object>}
 */
export function updateFraudTemplate(fraudTemplate) {
  const partialUrl = api.createUrl(getResources(fraudTemplate.id));
  return api.update(partialUrl, fraudTemplate.payload());
}
