import { api } from '.';
import { FRAUD_TEMPLATE_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {ResponseBuilder<{data:Offer[],meta:Object}>}
 */
export async function getFraudTemplates(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [FRAUD_TEMPLATE_RESOURCE]: undefined,
  });
  params.addInclude('advertiser,currency');
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
