import basicInfo from '@/router/private/modules/ecommerce/demand/offer/editForm/basicInfo';
import { default as newBasicInfo } from '@/router/private/modules/ecommerce/demand/offer/createForm/basicInfo';
import publishers from '@/router/private/modules/ecommerce/demand/offer/editForm/publishers';
import creatives from '@/router/private/modules/ecommerce/demand/offer/editForm/creatives';
import fraud from '@/router/private/modules/ecommerce/demand/offer/editForm/fraud';
import postEvent from '@/router/private/modules/ecommerce/demand/offer/editForm/postEvent';
import { default as cloneBasicInfo } from '@/router/private/modules/ecommerce/demand/offer/duplicateForm/basicInfo';
import { default as cloneFraud } from '@/router/private/modules/ecommerce/demand/offer/duplicateForm/fraud';
import { default as clonePostEvent } from '@/router/private/modules/ecommerce/demand/offer/duplicateForm/postEvent';
import { history as offerHistory } from '@/router/private/modules/ecommerce/demand/offer/editForm/history';
import { default as analytics } from '@/router/private/modules/ecommerce/analytics/stats';

const basicInfoProps = [
  'name',
  'advertiser',
  'country',
  'stattus',
  'start',
  'end',
  'campaign',
  'tags',
  'url',
  'priceType',
  'netPriceValue',
  'grossPriceValue',
  'currency',
  'priceValueType',
  'offerAttribution',
  'cap',
  'uniqueUserDailyCapping',
  'bundleBlackList',
  'domainBlackList',
  'uniqueConversion',
];
const fraudProps = ['offerFraud'];
const postEventProps = ['postEvents'];
const postEventAttributes = ['id', 'unique', 'netPrice', 'grossPrice', 'templateId'];

const tabNames = {
  basic: 'Basic Info',
  publishers: 'Publishers',
  creatives: 'Creatives',
  fraud: 'Fraud',
  postEvent: 'Post Event',
  offerHistory: 'History',
  analytics: 'Analytics',
};
const duplicateTabNames = {
  basic: 'Basic Info',
  fraud: 'Fraud',
  postEvent: 'Post Event',
};
const tabRouteDuplicate = {
  basic: cloneBasicInfo,
  fraud: cloneFraud,
  postEvent: clonePostEvent,
};
const tabRouteEdit = {
  basic: basicInfo,
  publishers,
  creatives,
  fraud,
  postEvent,
  offerHistory,
  analytics,
};
const tabRouteCreate = {
  basic: newBasicInfo,
  publishers: {},
  creatives: {},
  fraud: {},
  postEvent: {},
  offerHistory: {},
  analytics: {},
};
const OfferStatus = {
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  FINISHED: 'FINISHED',
};

export default Object.freeze({
  basicInfoProps,
  fraudProps,
  postEventProps,
  postEventAttributes,
  tabNames,
  duplicateTabNames,
  tabRouteEdit,
  tabRouteCreate,
  OfferStatus,
  tabRouteDuplicate,
  statusOptions: [
    { name: 'Active', value: 'ACTIVE' },
    { name: 'Paused', value: 'PAUSED' },
    { name: 'Finished', value: 'FINISHED' },
  ],
  lengthOfTimeOptions: [
    { name: '12 hours', value: 43200 },
    { name: '1 day', value: 86400 },
    { name: '7 day', value: 604800 },
    { name: '15 day', value: 1296000 },
    { name: '30 day', value: 2592000 },
  ],
  urlCreatorOptions: [
    { name: 'BUNDLE', description: 'It’s the name/id of the APP, and could be empty.' },
    { name: 'PUBLISHER_COMMISSION', description: 'Cost of our PUBLISHER (Never empty)' },
    { name: 'COUNTRY', description: 'User country' },
    { name: 'DOMAIN', description: 'Site_ID sent by our publisher (Sometimes empty)' },
    { name: 'IDFA', description: 'Unique ID Device: Google_ID in Android or IDFA in iOS (Sometimes empty)' },
    { name: 'IP', description: 'Ip of the user (never empty)' },
    { name: 'LANG', description: 'Browser language' },
    { name: 'OFFER_ID', description: 'Our offer id' },
    { name: 'EVENT_SESSION_ID', description: 'Our unique code' },
    { name: 'PUB_ID', description: 'Publisher ID (Never empty)' },
    { name: 'SUB_ID', description: 'Sub_Publisher sent by our publisher (Sometimes 0)' },
    { name: 'USER_AGENT', description: 'User Agent of the user' },
    { name: 'CUSTOM_ITEM_VALUE1', description: 'Add other parameters in the url' },
    { name: 'CUSTOM_ITEM_VALUE2', description: 'Add other parameters in the url' },
  ].map(({ name, description }) => ({
    name: `\${${name}}`,
    value: `\${${name}}`,
    description,
  })),
});
