import { api } from '.';
import { FRAUD_TEMPLATE_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

export const getResources = templateId => ({
  [FRAUD_TEMPLATE_RESOURCE]: templateId,
});

/**
 *
 * @param {string} templateId
 * @return {Promise<{Offer}>}
 */
export async function getFraudTemplateById(templateId) {
  const partialUrl = api.createUrl(getResources(templateId));
  const params = new QueryParamsBuilder();
  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
