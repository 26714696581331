var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("meta-actions-header", {
        staticClass: "mb-12",
        attrs: { "is-loading": _vm.isLoading },
        scopedSlots: _vm._u(
          [
            !_vm.isLoading
              ? {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "breadcrumb",
                        {
                          staticClass: "flex flex-1",
                          class: { "text-gray-700": _vm.offer && _vm.offer.id },
                        },
                        [
                          _vm.offer && _vm.offer.id
                            ? _c("span", [_vm._v(": ")])
                            : _vm._e(),
                          _vm.offer
                            ? _c("span", { staticClass: "text-orange-500" }, [
                                _vm._v(" " + _vm._s(_vm.offer.name)),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("tabs", {
        attrs: {
          "tabs-data": _vm.tabs,
          "base-offer": _vm.baseOffer,
          "is-loading": _vm.isLoading || _vm.isSaving,
          "view-mode": _vm.viewMode,
          "updated-offer": _vm.offer,
          "fraud-templates": _vm.fraudTemplates,
        },
        on: {
          locked: _vm.onTabLocked,
          dirty: _vm.onTabDirty,
          update: _vm.onOfferInfoUpdate,
          "tab-update": _vm.onUpdateTab,
          reload: function ($event) {
            return _vm.$emit("reload")
          },
          "update-offer": _vm.onUpdateOffer,
          duplicate: _vm.duplicateOffer,
          onClick: _vm.onClickTab,
          "update-post-events": _vm.setupdatePostEvents,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }