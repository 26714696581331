<template>
  <div>
    <meta-actions-header class="mb-12" :is-loading="isLoading">
      <template v-if="!isLoading" #header>
        <breadcrumb class="flex flex-1" :class="{ 'text-gray-700': offer && offer.id }"
          ><span v-if="offer && offer.id">: </span><span v-if="offer" class="text-orange-500"> {{ offer.name }}</span>
        </breadcrumb>
      </template>
    </meta-actions-header>

    <tabs
      :tabs-data="tabs"
      :base-offer="baseOffer"
      :is-loading="isLoading || isSaving"
      :view-mode="viewMode"
      :updated-offer="offer"
      :fraud-templates="fraudTemplates"
      @locked="onTabLocked"
      @dirty="onTabDirty"
      @update="onOfferInfoUpdate"
      @tab-update="onUpdateTab"
      @reload="$emit('reload')"
      @update-offer="onUpdateOffer"
      @duplicate="duplicateOffer"
      @onClick="onClickTab"
      @update-post-events="setupdatePostEvents"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { addDays } from 'date-fns/esm';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { OFFER_RESOURCE } from '@/services/keys';
import MetaActionsHeader from '@/components/organisms/shared/MetaActionsHeader';
import { updateOffer } from '@/services/modules/ecommerce/offer';
import { EcommerceApi } from '@/services/api/EcommerceApi';
import { Toast } from '@/model/shared/Toast';
import CONFIG from './config';
import Tabs from '@/components/atoms/Tabs';
import Tab from '@/model/shared/Tab';
import { edit as editOfferRoute } from '@/router/private/modules/ecommerce/demand/offer/edit.js';
import publishers from '@/router/private/modules/ecommerce/demand/offer/editForm/publishers';
import { createOffer } from '@/services/modules/ecommerce/offer/createOffer';
import { duplicateOffer } from '@/services/modules/ecommerce/offer/duplicateOffer';
import Offer from '@/entities/ecommerce/Offer';
import COST_TYPE_OPTIONS from '@/model/modules/ecommerce/offer/CostTypeOptions';
import Breadcrumb from '@/components/atoms/Breadcrumb';
import { dateToYYYYMMDD } from '@/filters/dateFilters';
import { BREAKDOWN_VALUES } from '@/model/modules/ecommerce/analytics/BreakdownValues';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import FILTERS_CONFIG from '@/model/modules/ecommerce/filtersConfig';
import { deepClone } from '@/utils/deepClone';
import { getFraudTemplates } from '@/services/modules/ecommerce/fraudTemplate';

export default {
  name: 'OfferForm',
  components: {
    Breadcrumb,
    MetaActionsHeader,
    Tabs,
  },
  props: {
    baseOffer: {
      /** @type Offer */
      type: Offer,
      default: () => null,
    },
    viewMode: {
      /** @type String */
      type: String,
      default: () => null,
    },
  },
  data: () => ({
    isLoading: true,
    /** @type Offer */
    offer: null,
    formErrors: {
      name: '',
      advertiser: null,
      url: null,
      percentage: null,
      currency: null,
    },
    defaultCurrency: null,
    statusOptions: CONFIG.statusOptions,
    costTypeOptions: COST_TYPE_OPTIONS,
    lengthOfTimeOptions: CONFIG.lengthOfTimeOptions,
    carrierList: [],
    tagList: [],
    // list of new tag to save with another endpoint.
    currentApi: new EcommerceApi(),
    resource: OFFER_RESOURCE,
    tabs: [],
    showAddPublisher: false,
    isSaving: false,
    offerIdClone: null,
    necesaryUpdate: true,
    updatePostEvents: false,
    fraudTemplates: [],
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
    }),
    isNewOffer() {
      return this.viewMode === 'create';
    },
    isDuplicate() {
      return this.viewMode === 'duplicate';
    },
    advertiserId() {
      return this.offer?.advertiser?.id;
    },
    isPublishersRouter() {
      return this.$route.name === publishers.name;
    },
    tabActive() {
      return this.tabs.find(tab => this.$route?.name === tab.route?.name);
    },
  },
  watch: {
    baseOffer(newOffer) {
      if (!this.offer) this.offer = deepClone(newOffer);
      if (JSON.stringify(this.offer.offerAttribution) !== JSON.stringify(newOffer.offerAttribution)) {
        this.$set(this.offer, 'offerAttribution', newOffer.offerAttribution);
      }
      if (JSON.stringify(this.offer.campaign) !== JSON.stringify(newOffer.campaign)) {
        this.$set(this.offer, 'campaign', newOffer.campaign);
      }
      if (JSON.stringify(this.offer.offerFraud) !== JSON.stringify(newOffer.offerFraud)) {
        this.$set(this.offer, 'offerFraud', newOffer.offerFraud);
      }
      if (JSON.stringify(this.offer.fraudTemplate) !== JSON.stringify(newOffer.fraudTemplate)) {
        this.$set(this.offer, 'fraudTemplate', newOffer.fraudTemplate);
      }
      if (JSON.stringify(this.offer.uniqueUserDailyCapping) !== JSON.stringify(newOffer.uniqueUserDailyCapping)) {
        this.$set(this.offer, 'uniqueUserDailyCapping', newOffer.uniqueUserDailyCapping);
      }
      if (this.updatePostEvents) {
        this.$set(this.offer, 'postEvents', newOffer.postEvents);
        this.updatePostEvents = false;
      }
      this.checkTabsDirty();
      this.isLoading = false;
    },
  },
  async mounted() {
    this.configTabs();
    await this.getFraudTemplates();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    metaTitleReplacement() {
      return { offer: this.offer?.name } || {};
    },
    async getFraudTemplates() {
      try {
        const auxParams = new QueryParamsBuilder(1, 500);
        const { data } = await getFraudTemplates(auxParams);
        this.fraudTemplates = data;
      } catch (error) {
        this.createToast(Toast.error(`We couldn't get fraud templates`, error.message));
      }
    },

    onUpdateOffer(offerUpdated) {
      this.offer = offerUpdated;
      this.checkTabsDirty();
    },
    setupdatePostEvents() {
      this.updatePostEvents = true;
    },
    checkTabsDirty() {
      if (!this.offer) return null;
      let basicDirty = false;
      let fraudDirty = false;
      let postEventDirty = false;
      const offPostEvents = this.offer[CONFIG.postEventProps[0]].map(pe => {
        return { id: pe.id, netPrice: pe.netPrice, grossPrice: pe.grossPrice, templateId: pe.templateId };
      });
      const baseOffPostEvents = this.baseOffer[CONFIG.postEventProps[0]].map(pe => {
        return { id: pe.id, netPrice: pe.netPrice, grossPrice: pe.grossPrice, templateId: pe.templateId };
      });
      CONFIG.basicInfoProps.forEach(key => {
        if (JSON.stringify(this.offer[key]) !== JSON.stringify(this.baseOffer[key])) {
          basicDirty = true;
        }
      });

      Object.entries(this.offer[CONFIG.fraudProps[0]]).forEach(entity => {
        const [key, value] = entity;
        if (JSON.stringify(this.baseOffer[CONFIG.fraudProps[0]][key]) !== JSON.stringify(value)) {
          fraudDirty = true;
        }
      });
      if (
        JSON.stringify(offPostEvents.sort((a, b) => a.id > b.id)) !==
        JSON.stringify(baseOffPostEvents.sort((a, b) => a.id > b.id))
      ) {
        postEventDirty = true;
      }
      this.onUpdateTab(new Tab(CONFIG.tabNames.basic, null, basicDirty, false));
      this.onUpdateTab(new Tab(CONFIG.tabNames.fraud, null, fraudDirty, false));
      this.onUpdateTab(new Tab(CONFIG.tabNames.postEvent, null, postEventDirty, false));
    },
    configTabs() {
      if (!this.isDuplicate) {
        this.tabs = Object.entries(CONFIG.tabNames).map(([key, name]) => {
          const routes = this.isNewOffer ? CONFIG.tabRouteCreate : CONFIG.tabRouteEdit;
          const isLocked = key !== 'basic' && this.isNewOffer;

          const tab = new Tab(name, routes[key], false, isLocked);

          if (name === 'Analytics') {
            tab.isFakeTab = true;
          }
          return tab;
        });
      } else {
        this.tabs = Object.entries(CONFIG.duplicateTabNames).map(([key, name]) => {
          const routes = CONFIG.tabRouteDuplicate;
          return new Tab(name, routes[key], false, false);
        });
      }
    },
    onUpdateTab(tab) {
      const tabIndex = this.tabs.find(t => t.name === tab.name);
      this.$set(tabIndex, 'dirty', tab.dirty);
    },
    async onOfferInfoUpdate(offer) {
      this.isSaving = true;
      const toastText = {};
      try {
        if (this.isNewOffer) {
          const { id } = await createOffer(offer, this.activeClient.id);
          this.offer.id = id;
          toastText.title = 'Offer created';
          toastText.msg = `Offer ${offer.name} was created successfully.`;
          await this.$router.push({ name: editOfferRoute.name, params: { offerId: offer.id } });
        } else {
          await updateOffer(offer);
          toastText.title = 'Offer updated';
          toastText.msg = `Offer ${offer.name} was updated successfully.`;
          this.$emit('reload');
        }
        this.createToast(Toast.success(toastText.title, toastText.msg));
      } catch (error) {
        this.createToast(Toast.error('Error!', error.message));
      } finally {
        this.isSaving = false;
      }
    },
    onTabLocked(tab) {
      this.createToast(
        Toast.warning('Locked!', `To view the ${tab.name} section. You must complete the offer basic info`)
      );
    },
    onTabDirty() {
      this.createToast(Toast.warning('Warning!', `You have unsaved work`));
    },
    async duplicateOffer(offerToClone) {
      this.isSaving = true;
      offerToClone.id = this.baseOffer.id;
      try {
        const { data } = await duplicateOffer(offerToClone, this.activeClient.id);
        this.createToast(Toast.success('Success!', `Offer ${offerToClone.name} has been created`));
        await this.$router.push({ name: editOfferRoute.name, params: { offerId: data.offer.id } });
      } catch (error) {
        this.createToast(Toast.error('Error!', error.message));
        throw error;
      } finally {
        this.isSaving = false;
      }
    },
    onClickTab(tab) {
      const params = new QueryParamsBuilder();
      params.addFilter(FILTERS_CONFIG.OFFER_ID.key, this.$route.params.offerId);
      params.setBreakdown(BREAKDOWN_VALUES.day);

      const endDate = new Date();
      const startDate = addDays(endDate, -7);
      params.addFilter('range_start', dateToYYYYMMDD(startDate.setHours(0, 0, 0, 0), true));
      params.addFilter('range_end', dateToYYYYMMDD(endDate.setHours(23, 59, 59, 0), true));

      this.$router.push({
        name: tab.route.name,
        query: params.buildWithoutPage(),
      });
    },
  },
};
</script>
