import { api } from '.';
import { FRAUD_TEMPLATE_RESOURCE } from '@/services/keys';

/**
 *
 * @param {string} templateId
 * @returns {Promise<object>}
 */
export async function deleteFraudTemplate(templateId) {
  const partialUrl = api.createUrl({
    [FRAUD_TEMPLATE_RESOURCE]: templateId,
  });
  const { data } = await api.delete(partialUrl);
  return data;
}
