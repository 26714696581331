import { api } from '.';
import { OFFER_RESOURCE, DUPLICATE_RESOURCE } from '@/services/keys';

export const getResources = offerId => ({
  [OFFER_RESOURCE]: offerId,
  [DUPLICATE_RESOURCE]: null,
});

/**
 * @param {Offer} offer
 * @returns {Promise.<object>}
 */
export async function duplicateOffer(offer, clientId = null) {
  const partialUrl = api.createUrl(getResources(offer.id));
  offer.id = null;
  const offerFormated = offer.clonePayload();
  if (clientId) offerFormated.clientId = clientId;
  return await api.create(partialUrl, offerFormated);
}
